<template>
    <div class="includepage">
            <section class="blockElement space meetTeam pt-0 bg-white">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-10 text-center">
                            <h2 class="mb-md-2"><i class="secondary">{{$t('about.text74')}}</i></h2>
                            <h2 class="mb-md-4 pb-4">{{$t('about.text340')}} <i class="secondary">{{$t('about.text75')}}</i>, {{$t('about.text76')}} <i class="secondary">{{$t('about.text77')}}</i></h2>
                            <h5>{{$t('about.textTit')}}</h5>
                            <p class="medium">{{$t('about.text78')}}</p>
                            <h5>{{$t('about.textQus')}}</h5>
                            <p>{{$t('about.text79')}}</p>
                            <p>{{$t('about.text80')}}</p>
                            <!-- <a href="javascript:void(0)" class="bold button fillBtn zulu_btn px-md-5 mt-4">Find Your Calling</a> -->
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space lifeZuluTrade bg-white">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-12 col-lg-10 text-center mb-md-4 pb-4">
                            <!-- <span class="roundedQuotes"><v-lazy-image src="/assets/images/quotes.svg" alt="qoutes" /></span> -->
                            <h2 class="mb-2">{{$t('about.text81')}}</h2>
                            <h6 class="mb-0"><span class="secondary uppercase">{{$t('about.text82')}}</span></h6>
                        </div>
                        <div class="col-12 col-lg-12">
                            <carousel class="mt-0 text-start" :settings="carousleSettings" :wrapAround="true" :transition="500" :breakpoints="breakpoints">
                                <slide :key="1">
                                    <div class="carousel__item h-100">
                                        <div class="card-body darkCard">
                                            <p>{{$t('about.text89')}}</p>
                                            <p>{{$t('about.text90')}}</p>
                                            <p>{{$t('about.text91')}}</p>
                                            <p>{{$t('about.text92')}}</p>
                                            <div class="nameQuotees">
                                                <h3 class="bold mb-0">{{$t('about.text93')}}</h3>
                                                <p class="mb-0 secondary">{{$t('about.text94')}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </slide>
                                <slide :key="2">
                                    <div class="carousel__item h-100">
                                        <div class="card-body darkCard">
                                            <p>{{$t('about.text95')}}</p>
                                            <p>{{$t('about.text96')}}</p>
                                            <p>{{$t('about.text97')}}</p>
                                            <p>{{$t('about.text98')}}</p>
                                            <div class="nameQuotees">
                                                <h3 class="bold mb-0">{{$t('about.text99')}}</h3>
                                                <p class="mb-0 secondary">{{$t('about.text100')}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </slide>
                                <slide :key="3">
                                    <div class="carousel__item h-100">
                                        <div class="card-body darkCard">
                                            <p>{{$t('about.text101')}}</p>
                                            <p>{{$t('about.text102')}}</p>
                                            <p>{{$t('about.text103')}}</p>
                                            <p>{{$t('about.text104')}}</p>
                                            <div class="nameQuotees">
                                                <h3 class="bold mb-0">{{$t('about.text105')}}</h3>
                                                <p class="mb-0 secondary">{{$t('about.text106')}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </slide>
                                <slide :key="4">
                                    <div class="carousel__item h-100">
                                        <div class="card-body darkCard">
                                            <p>{{$t('about.text95')}}</p>
                                            <p>{{$t('about.text96')}}</p>
                                            <p>{{$t('about.text97')}}</p>
                                            <p>{{$t('about.text98')}}</p>
                                            <div class="nameQuotees">
                                                <h3 class="bold mb-0">{{$t('about.text99')}}</h3>
                                                <p class="mb-0 secondary">{{$t('about.text100')}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </slide>
                                <template #addons="{ slidesCount }">
                                    <navigation v-if="slidesCount > 1"/>
                                </template>
                            </carousel>
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space makesZuluTrade bg-white">
                <div class="container">
                    <div class="row">
                        <div class="col-12 mb-md-4">
                            <h2>{{$t('about.text107')}} <span class="secondary">{{$t('about.text108')}}</span></h2>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                            <div class="card-body darkCard">
                                <v-lazy-image class="mb-2" src="/assets/images/layer_1.svg" :alt="$t('about.text428')" />
                                <h6><span class="secondary">{{$t('about.text109')}}</span>{{$t('about.text110')}}</h6>
                                <p class="mb-0">{{$t('about.text111')}}</p>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                            <div class="card-body darkCard">
                                <v-lazy-image class="mb-2" src="/assets/images/layer_2.svg" :alt="$t('about.text428')" />
                                <h6><span class="secondary">{{$t('about.text109')}}</span>{{$t('about.text112')}}</h6>
                                <p class="mb-0">{{$t('about.text113')}}</p>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                            <div class="card-body darkCard">
                                <v-lazy-image class="mb-2" src="/assets/images/layer_3.svg" :alt="$t('about.text428')" />
                                <h6><span class="secondary">{{$t('about.text114')}}</span>{{$t('about.text115')}}</h6>
                                <p class="mb-0">{{$t('about.text116')}}</p>
                            </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
                            <div class="card-body darkCard">
                                <v-lazy-image class="mb-2" src="/assets/images/layer_4.svg" :alt="$t('about.text428')" />
                                <h6><span class="secondary">{{$t('about.text114')}}</span>  {{$t('about.text117')}}</h6>
                                <p class="mb-0">{{$t('about.text118')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space experienceZuluTrade bg-white">
                <div class="container">
                    <div class="row g-3 text-center">
                        <div class="col-12 mb-md-4">
                            <h2>{{$t('about.text58')}} <span class="secondary">{{$t('about.text55')}}</span> {{$t('about.text119')}}</h2>
                            <p>{{$t('about.text120')}}</p>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <span class="gridImg"><v-lazy-image height="228" class="mb-2 w-100" src="/assets/images/galleryRow-6.png" :alt="$t('about.text429')" /></span>
                            <!-- <span class="gridImg"><v-lazy-image height="176" class="mb-2 w-100" src="/assets/images/galleryRow-1.png" alt="Photo gallery 1" /></span> -->
                            <span class="gridImg"><v-lazy-image height="201" class="mb-2 w-100" src="/assets/images/galleryRow-2.png" :alt="$t('about.text430')" /></span>
                            <span class="gridImg"><v-lazy-image height="180" class="mb-2 w-100" src="/assets/images/galleryRow-3-5.png" :alt="$t('about.text431')" /></span>
                            <!-- <span class="gridImg"><v-lazy-image class="mb-2 w-100" src="/assets/images/galleryRow-4.jpg" alt="Photo gallery 4" /></span> -->
                            <!-- <span class="gridImg"><v-lazy-image height="260" class="mb-2 w-100" src="/assets/images/galleryRow-5.png" :alt="$t('about.text429')" /></span> -->
                           
                        </div>
                        <div class="col-12 col-md-6 col-lg-2">
                            <span class="gridImg"><v-lazy-image height="308" class="mb-2" src="/assets/images/galleryRow-2-4.png" :alt="$t('about.text432')" /></span>
                            <span class="gridImg"><v-lazy-image height="312" class="mb-2" src="/assets/images/galleryRow-2-1.png" :alt="$t('about.text433')" /></span>
                            <!-- <span class="gridImg"><v-lazy-image height="247" class="mb-2" src="/assets/images/galleryRow-2-2.png" :alt="$t('about.text434')" /></span> -->
                            <!-- <span class="gridImg"><v-lazy-image height="434" class="mb-2" src="/assets/images/galleryRow-2-3.png" alt="Photo gallery 15" /></span> -->
                           
                        </div>
                        <div class="col-12 col-md-6 col-lg-4">
                            <span class="gridImg"><v-lazy-image height="231" class="mb-2" src="/assets/images/galleryRow-3.png" :alt="$t('about.text435')" /></span>
                            <span class="gridImg"><v-lazy-image height="383" class="mb-2" src="/assets/images/galleryRow-3-1.png" :alt="$t('about.text436')" /></span>
                            <!-- <span class="gridImg"><v-lazy-image height="205" class="mb-2" src="/assets/images/galleryRow-3-2.png" alt="Photo gallery 17" /></span> -->
                            <!-- <span class="gridImg"><v-lazy-image height="220" class="mb-2" src="/assets/images/galleryRow-3-3.png" :alt="$t('about.text437')" /></span> -->
                            <!-- <span class="gridImg"><v-lazy-image height="204" class="mb-2" src="/assets/images/galleryRow-3-4.png" :alt="$t('about.text435')" /></span> -->
                           
                        </div>
                        <div class="col-12 col-md-6 col-lg-2">
                            <span class="gridImg"><v-lazy-image height="235" class="mb-2" src="/assets/images/galleryRow-4-4.png" :alt="$t('about.text435')" /></span>
                            <span class="gridImg"><v-lazy-image height="380" class="mb-2" src="/assets/images/galleryRow-4-1.png" :alt="$t('about.text438')" /></span>
                            <!-- <span class="gridImg"><v-lazy-image height="464" class="mb-2" src="/assets/images/galleryRow-2-3.png" :alt="$t('about.text439')" /></span> -->
                            <!-- <span class="gridImg"><v-lazy-image height="438" class="mb-2" src="/assets/images/galleryRow-4-3.jpg" alt="Photo gallery 19" /></span> -->
                            
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space whatWeDo">
                <div class="container">
                    <div class="row g-2 text-center">
                        <div class="col-12 mb-md-4">
                            <h2>{{$t('about.text121')}} <span class="secondary">{{$t('about.text122')}}</span> {{$t('about.text123')}} <br> {{$t('about.text124')}} <span class="secondary">{{$t('about.text125')}}</span>, {{$t('about.text126')}}</h2>
                            <p class="mb-0">{{$t('about.text127')}}</p> 
                            <p>{{$t('about.text128')}}</p>
                        </div>
                        <div class="col-12 col-md-4 mb-3 mb-md-0">
                            <div class="card-body darkCard">
                                <v-lazy-image class="mb-2" src="/assets/images/weCan-1.svg" :alt="$t('about.text428')" />
                                <h6><span class="d-block">{{$t('about.text129')}}</span> {{$t('about.text130')}}</h6>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 mb-3 mb-md-0">
                            <div class="card-body darkCard">
                                <v-lazy-image class="mb-2" src="/assets/images/weCan-2.svg" :alt="$t('about.text428')" />
                                <h6><span class="d-block">{{$t('about.text131')}}</span>{{$t('about.text132')}}</h6>
                            </div>
                        </div>
                        <div class="col-12 col-md-4 mb-3 mb-md-0">
                            <div class="card-body darkCard">
                                <v-lazy-image class="mb-2" src="/assets/images/weCan-3.svg" :alt="$t('about.text428')" />
                                <h6><span class="d-block">{{$t('about.text133')}}</span> {{$t('about.text134')}}</h6>
                            </div>
                        </div>
                        <div class="col-12 mt-3 mt-md-5">
                            <a target="_blank" href="https://apply.workable.com/finvasia-greece/" class="bold button fillBtn zulu_btn px-md-5">{{$t('about.text135')}}</a>
                        </div>
                    </div>
                </div>
            </section>
            <section class="blockElement space otherBenefits">
                <div class="container">
                    <div class="row justify-content-center text-center">
                        <div class="col-12 mb-md-4">
                            <h2>{{$t('about.text136')}}  <span class="secondary">{{$t('about.text137')}}</span> </h2>
                        </div>
                        <div class="col-12 col-md-7">
                            <div class="proLink">
                                <a class="button zulu_btn rounded border-button" href="javascript:void(0)" target="_blank">{{$t('about.text138')}}</a>
                                <a class="button zulu_btn rounded border-button" href="javascript:void(0)" target="_blank">{{$t('about.text139')}}</a>
                                <a class="button zulu_btn rounded border-button" href="javascript:void(0)" target="_blank">{{$t('about.text140')}}</a>
                                <a class="button zulu_btn rounded border-button" href="javascript:void(0)" target="_blank">{{$t('about.text141')}}</a>
                                <a class="button zulu_btn rounded border-button" href="javascript:void(0)" target="_blank">{{$t('about.text142')}}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
</template>
<script>
import { Carousel, Slide, Navigation } from 'vue3-carousel'
export default {
    data() {
        return {
            carousleSettings: {
                itemsToShow: 1.99,
                itemsToScroll: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                320: {
                    itemsToShow: 1,
                    itemsToScroll:1,
                    snapAlign: 'center',
                },
                767: {
                    itemsToShow: 1.99,
                    itemsToScroll: 1,
                    snapAlign: 'center',
                },
            },
        };
    },
    components: {
        Carousel, Slide, Navigation
    },
}
</script>